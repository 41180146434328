@import "./breakPoints.scss";
@import "../variables.scss";

.button {
    background-color: $mainColor;
    color: white;
    border: 0;
    height: 45px;
    min-width: 100px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    transition: $transition;
    box-shadow: $buttonShadow;

    &:hover {
        border: 1px solid $mainColor;
        background-color: white;
        color: $mainColor;
    }
}

.button-appointment {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid white;
    height: 45px;
    width: 220px;
}

.button-check {
    width: 100%;
    border: 1px solid $borderColor;
    background-color: white;
    color: $mainColor;
    font-weight: 700;
    transition: 0.5s;

    &:hover {
        border-color: $semiColor;
        background-color: $borderColor;
    }
}

.button-row {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 230px;
    border: 0;
    &:hover {
        border: 1px solid $mainColor;
        background-color: $separateMainColor;
        color: $mainColor;
    }
}

.button-white-row {
    background-color: white;
    color: $semiColor;
    border: 1px solid $borderColor;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(33.333% - 13px);
    height: 60px;
    font-size: 17px;
}

.google-pay-button {
    max-width: 208px;
}

.apple-pay-button {
    max-width: 208px;
}

.button-signup {
    width: 100%;
    border: none;
    cursor: pointer;

    &.disable {
        background: #6f6f6f!important;
        border: 1px solid #fff!important;
        cursor: not-allowed;
        &:hover {
            color: #FFFFFF;
            border: none;
        }
    }
}

.button-book {
    width: 180px;
    height: 40px;
}

.button-appointment {
    width: 205px;
    height: 45px;
    box-shadow: $shadow;
}

.button-back-auth {
    border-radius: 100%;
    color: $semiColor;
    background-color: white;
    height: 40px;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-letter-padding {
    padding-left: 5px;
}

.button-letter-padding-animated {
    animation: color-transition 4s linear infinite;

    &:hover {
        background-color: white!important;
    }
}

@keyframes color-transition {
    0% {
        background-color: #1a81e0;
    }
    33% {
        background-color: #479CEA;
    }
    66% {
        background-color: #1c81d9;
    }
    100% {
        background-color: #1d81e0;
    }
}

.modal-submit-button {
    min-width: 180px;
    height: 45px;
}

.modal-cancel-button {
    min-width: 180px;
    height: 45px;
    background-color: white;
    color: $blackColor;

    &:hover {
        color: $blackColor;
    }
}

.button-match-active {
    min-width: 160px;
}

.button-match-inactive {
    background-color: white;
    color: $blackColor;
    min-width: 160px;
}

.button-signup-social {
    background-color: white;
    color: $blackColor;
    width: 100%;
    border: none;
}

.button-edit {
    background-color: $backgroundColor;
    color: #4D9DE7;
    min-width: 80px;
    border-radius: 15px;
    height: 30px;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 16px 8px 16px;
    box-shadow: none;
    border: none;
    width: fit-content;
    border: 1px solid $separateMainColor;
}

.button-add-insurance {
    background-color: white;
    padding: 0 24px;
    min-width: 150px;
    color: $semiColor;
    border: 1px solid $semiColor;
    font-weight: 500;
}

.button-white {
    background-color: white;
    color: $blackColor;

    &:hover {
        color: $blackColor;
    }
}

.button-upload {
    border-radius: 15px;
    background-color: $activeBack;
    // opacity: 0.07;
    height: auto;
    padding: 8px 16px;
    color: $semiColor;
    box-shadow: none;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;

    &:hover {
        border: none;
        background-color: $activeBack;
    }
}

.button-calendar {
    padding: 13px 0;
    background-color: white;
    color: $blackColor;
    font-weight: 500;
    font-size: 13px;
    line-height: 25px;
    animation: textOpacity 0.5s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
        background-color: $mainColor;
        color: white;
        border: none;
    }

    &:hover {
        background-color: $mainColor;
        color: white;
        border: none;
    }
}

.button-calendar-busy {
    background-color: #E8EFF8;
    border: none;
    color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
    box-shadow: none;
    display: none;

    &:hover {
        background-color: #E8EFF8;
        color: rgba(0, 0, 0, 0.2);
        box-shadow: none;
    }

}

.button-overwrited {
    background-color: $activeBack;
    border: 1px solid $semiColor;
}

.button-calendar-disabled {
    background-color: #ebf2fb;
    box-shadow: none;
    border: 1px solid rgba(50, 57, 69, 0.1);
    color: rgba(0, 0, 0, 0.5);
    // pointer-events: none;

    &:hover {
        background-color: #ebf2fb;
        border: 1px solid rgba(50, 57, 69, 0.1);
        color: $blackColor;
        cursor: not-allowed;
    }
}

.chat-button {
    padding: 12px 9px 9px 9px;
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
    box-shadow: $buttonShadow;
    border-radius: 100px;
    position: relative;

    .button-dot {
        background-color: $mainColor;
        border-radius: 100px;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            content: "";
            width: 8px;
            height: 8px;
            background-color: $semiColor;
            display: block;
            border-radius: 100px;
        }
    }
}

@keyframes textOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: $sm) {
    .button {
        height: 45px;
        min-width: 0;
        width: 100%;
        border: 0;
    }
    .button-calendar {
        min-width: 90px;
    }
    .button-edit {
        height: 30px;
        width: fit-content;
    }
}
